import Swal from "sweetalert2";

export const alertMixin = {
  methods: {
    showChangesSavedToast() {
      this.$bvToast.toast("Ihre Änderungen wurden gespeichert", {
        title: "Gespeichert",
        variant: "success",
        solid: true,
        toaster: "b-toaster-top-center"
      });
    },
    showCheckProvidedDataAlert() {
      this.showErrorAlert("", "Bitte überprüfen Sie ihre Angaben!");
    },
    showChangesSavedAlert() {
      this.showSuccessAlert("", "Ihre Änderungen wurden gespeichert!");
    },
    showToMuchFilesAlert(maxNrOfImages, remainingNrOfImages) {
      this.showErrorAlert(
        "Zu viele Bilder ausgewählt",
        "Sie können noch " +
          remainingNrOfImages +
          " von " +
          maxNrOfImages +
          " Bilder hochladen."
      );
    },
    showReCaptchaError() {
      this.showErrorAlert(
        "ReCaptcha Fehler",
        "Die ReCaptcha Validierung ist fehlgeschlagen. Bitte versuchen Sie es erneut."
      );
    },
    showSuccessAlert(title, text) {
      Swal.fire({
        title: title,
        text: text,
        icon: "success",
        customClass: {
          confirmButton: "btn btn-secondary"
        }
      });
    },
    showErrorAlert(title, text) {
      Swal.fire({
        title: title,
        text: text,
        icon: "error",
        customClass: {
          confirmButton: "btn btn-secondary"
        }
      });
    }
  }
};
