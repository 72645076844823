<template>
  <div>
    <!--begin::Card-->
    <div class="card card-custom">
      <!--begin::Body-->
      <div class="card-body py-8 px-8 py-lg-20 px-lg-10">
        <div class="row">
          <div class="offset-xxl-2 col-xxl-8">
            <h4 class="mb-10 font-weight-bold text-dark">
              Basisabonement
            </h4>

            <SubscriptionPropertyLine
              name="Abgeschlossen am"
              :property="formatDateTime(subscriptionOrder.start_date)"
            />
            <SubscriptionPropertyLine
              name="Endet am"
              :property="formatDateTime(subscriptionOrder.end_date)"
            />
            <SubscriptionPropertyLine
              name="Kann angepasst werden ab dem"
              :property="formatDateTime(subscriptionOrder.adjustment_date)"
            />

            <div class="pt-5" />

            <SubscriptionPropertyLine
              name="Firmenlogo"
              :property="subscriptionOrder.has_logo ? 'Ja' : 'Nein'"
            />
            <SubscriptionPropertyLine
              name="Anzahl Niederlassungen"
              :property="subscriptionOrder.nr_of_branch_offices"
            />
            <SubscriptionPropertyLine name="Anzahl BKP Nummern" property="1" />
            <SubscriptionPropertyLine
              name="Anzahl zusätzliche BKP Nummern"
              :property="subscriptionOrder.nr_of_additional_bkp"
            />
            <SubscriptionPropertyLine
              name="Firmenportrait"
              :property="subscriptionOrder.has_portrait ? 'Ja' : 'Nein'"
            />

            <div class="border-bottom mt-10" />

            <h4 class="mb-10 mt-10 font-weight-bold text-dark">
              Preise
            </h4>
            <SubscriptionPropertyLine
              name="Grundpreis Jahresabo inkl. 1 BKP"
              prefix="CHF"
              :property="formatFloat(subscriptionOrder.base_price)"
            />
            <SubscriptionPropertyLine
              name="Firmenlogo"
              prefix="CHF"
              :property="formatFloat(subscriptionOrder.logo_price)"
            />
            <SubscriptionPropertyLine
              name="Niederlassung"
              prefix="CHF"
              :property="formatFloat(subscriptionOrder.branch_office_price)"
            />
            <SubscriptionPropertyLine
              name="Zusätzliche BKP Nummer"
              prefix="CHF"
              :property="formatFloat(subscriptionOrder.additional_bkp_price)"
            />
            <SubscriptionPropertyLine
              name="Firmenportrait"
              :property="'CHF ' + formatFloat(subscriptionOrder.portrait_price)"
            />

            <div class="border-bottom mt-10" />

            <h4 class="mb-10 mt-10 font-weight-bold text-dark">
              Kosten
            </h4>
            <SubscriptionPropertyLine
              name="Kosten Jahresabo inkl. 1 BKP"
              prefix="CHF"
              :property="formatFloat(subscriptionOrder.base_price)"
            />
            <SubscriptionPropertyLine
              name="Kosten Logo"
              prefix="CHF"
              :property="formatFloat(subscriptionOrder.total_logo_price)"
            />
            <SubscriptionPropertyLine
              name="Kosten Niederlassungen"
              prefix="CHF"
              :property="
                formatFloat(subscriptionOrder.total_branch_offices_price)
              "
            />
            <SubscriptionPropertyLine
              name="Kosten zusätzliche BKP Nummern"
              prefix="CHF"
              :property="
                formatFloat(subscriptionOrder.total_additional_bkp_price)
              "
            />
            <SubscriptionPropertyLine
              name="Kosten Firmenportrait"
              :property="
                'CHF ' + formatFloat(subscriptionOrder.total_portrait_price)
              "
            />
            <SubscriptionPropertyLine
              name="Kosten Jahresabo mit gewählten Optionen"
              :property="
                'CHF ' + formatFloat(subscriptionOrder.total_base_price)
              "
              :isBold="true"
            />

            <div class="pt-5" />

            <SubscriptionPropertyLine
              name="Vertragslaufzeit"
              :property="subscriptionOrder.nr_of_years"
              postfix="Jahre"
            />
            <SubscriptionPropertyLine
              name="Listenpreis (Basispreis * Vertragslaufzeit)"
              prefix="CHF"
              :property="formatFloat(subscriptionOrder.total_list_price)"
            />
            <SubscriptionPropertyLine
              name="Rabatt Einladungscode"
              :property="
                formatFloat(
                  subscriptionOrder.user_invitation_discount_percentage
                )
              "
              postfix="%"
            />
            <SubscriptionPropertyLine
              name="Rabatt Laufzeit"
              :property="
                formatFloat(
                  subscriptionOrder.total_discount_percentage -
                    subscriptionOrder.user_invitation_discount_percentage
                )
              "
              postfix="%"
            />
            <SubscriptionPropertyLine
              name="Rabatt Total"
              :property="
                formatFloat(subscriptionOrder.total_discount_percentage)
              "
              postfix="%"
              :isBold="true"
            />
            <SubscriptionPropertyLine
              name="Ersparnis"
              prefix="CHF"
              :property="
                formatFloat(
                  subscriptionOrder.total_list_price -
                    subscriptionOrder.total_discount_price
                )
              "
            />
            <SubscriptionPropertyLine
              name="Gesamtpreis nach Abzug Rabatt"
              prefix="CHF"
              :property="formatFloat(subscriptionOrder.total_discount_price)"
              :isBold="true"
            />

            <div class="pt-5" />
            <SubscriptionPropertyLine
              name="Skonto %"
              :property="
                formatFloat(subscriptionOrder.total_cash_discount_percentage)
              "
              postfix="%"
            />
            <SubscriptionPropertyLine
              name="Ersparnis"
              prefix="CHF"
              :property="
                formatFloat(
                  subscriptionOrder.total_discount_price -
                    subscriptionOrder.total_cash_discount_price
                )
              "
            />
            <SubscriptionPropertyLine
              name="Gesamtkosten nach Abzug Skonto"
              prefix="CHF"
              :property="
                formatFloat(subscriptionOrder.total_cash_discount_price)
              "
            />
            <SubscriptionPropertyLine
              name="Durchnittspreis pro Jahr"
              prefix="CHF"
              :property="
                formatFloat(
                  subscriptionOrder.total_cash_discount_price /
                    subscriptionOrder.nr_of_years
                )
              "
            />
            <SubscriptionPropertyLine
              name="Total Ersparnis gegenüber dem Listenpreis"
              prefix="CHF"
              :property="
                formatFloat(
                  subscriptionOrder.total_list_price -
                    subscriptionOrder.total_cash_discount_price
                )
              "
            />
            <SubscriptionPropertyLine
              name="Total Ersparnis in %"
              :property="
                formatFloat(subscriptionOrder.total_subscription_percentage)
              "
              postfix="%"
            />

            <div class="pt-5" />

            <SubscriptionPropertySummaryLine
              name="Total Kosten"
              prefix="CHF"
              :property="
                formatFloat(subscriptionOrder.total_subscription_price)
              "
            />
          </div>
        </div>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { utilMixin } from "@/mixins/utilMixin";
import { alertMixin } from "@/mixins/alertMixin";
import SubscriptionPropertyLine from "@/components/SubscriptionPropertyLine";
import SubscriptionPropertySummaryLine from "@/components/SubscriptionPropertySummaryLine";
import { LOAD_DASHBOARD_SUBSCRIPTION_ORDER } from "@/store/dashboard.module";

export default {
  name: "SubscriptionOrder",
  components: { SubscriptionPropertyLine, SubscriptionPropertySummaryLine },
  mixins: [utilMixin, alertMixin],
  data() {
    return {
      subscriptionOrder: {},
      subscriptionOrderId: this.$route.params.orderId
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      // Load first the pricing before the company is loaded
      Promise.all([
        this.$store.dispatch(LOAD_DASHBOARD_SUBSCRIPTION_ORDER, {
          id: this.subscriptionOrderId
        })
      ]).then(() => {
        this.subscriptionOrder = this.getDashboardSubscriptionOrder;
      });
    }
  },
  computed: {
    ...mapGetters(["getDashboardSubscriptionOrder"])
  }
};
</script>
